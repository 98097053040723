import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../../components/seo";
import Layout from "../../components/layout";

import "../../styles/blog.scss";

export default function Index({ data }: any) {
    const { edges: posts } = data.allMarkdownRemark;
    return (
        <>
            <Layout>
                <Seo title="Blog"/>
                <p>Kickass Presbyterian's blog posts, starting with the most recent. Search functionality will
                be added eventually.</p>
                <div className="blog-posts">
                    { posts
                        .filter((post: any) =>
                            post.node.frontmatter.title.length > 0 && post.node.frontmatter.published == "true"
                        )
                        .map(({ node: post }) => {
                            return (
                                <div className="blog-post-preview" key={ post.id }>
                                    <div className="blog-post-title">
                                        <h2>{ post.frontmatter.title }</h2>
                                        <h4>{ post.frontmatter.date } - { post.frontmatter.readTime } read</h4>
                                    </div>
                                    <div className="blog-post-snippet">
                                        <p>{ post.excerpt }</p>
                                    </div>
                                    <div className="blog-post-read-more">
                                        <Link to={ post.frontmatter.path }>Read More</Link>
                                    </div>
                                </div>
                            )
                        }) }
                </div>
            </Layout>
        </>
    );
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            readTime
            published
          }
        }
      }
    }
  }
`;
